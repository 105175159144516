.why-gale-section.container-fluid {
  padding-top: 30px;
  width: 100vw;
  height: 100%;
}

.fill {
  width: 100%;
}

.icon{
  width: 20px;
}

.why-gale-section-header {
  text-align: left;
}

.why-gale-section-text {
  text-align: left;
}

.float-full-form{
  margin-top:-30px;
  font-size: 1.2vw;
}


@media (min-width: 768px) {

  .why-gale-section-header {
    font-size: 2.2vw;
  }

  .why-gale-section-text__responsive{
    font-size: 1.2vw;
    padding-left: calc(16px - (1640px - 100vw)/152);
  }

  .icon{
    width: calc(27px - (1640px - 100vw)/152);
  }
}

@media (max-width: 768px) {

  .float-full-form{
    margin-top:-30px;
    font-size: 16px;
  }

  .why-gale-section-header {
    font-size: 36px;
  }

  .why-gale-section-text__responsive{
    font-size: 16px;
    padding-left: 1rem;
  }

  .icon{
    width: 27px;
  }

  .why-gale-section-content{
    padding-bottom: 5vw
  }
}

