.features-section {
  width: 100vw;
  height: 100%;
}

.slider-features.slick-slider.slick-initialized{
  width:80vw;
}

.slick-prev,
.slick-next {
  height: 40px;
  width: 40px;
  background: white;
  color: #fff;
  box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.25);
  /* to position the arrows left and right at the bottom of slider */
  position: absolute;
  z-index: 1;
  bottom: 0;
  border-radius: 50%;
}

.slick-arrow.slick-prev:hover,
.slick-arrow.slick-next:hover{
  background: white;
}

.slick-prev{
  margin-left:-50px;
}

.slick-next{
  margin-right:-50px;
}

.slick-dots.show-dots{
  margin-top:-30px;
  margin-bottom:30px;
}

.slick-current .feature-card{
  border-top-color: #123b8b;
  border-top-width: 0.3vw;
  transform: scale(1.2);
  -webkit-transition: border-top-color 2s ease-out;
  -moz-transition: border-top-color 2s ease-out;
  -o-transition: border-top-color 2s ease-out;
  transition: border-top-color 2s ease-out, transform 0.5s;
}

.feature-image{
  -webkit-filter: drop-shadow(1px 1px 20px rgba(0, 0, 0, 0.20));
  filter: drop-shadow(1px 1px 20px rgba(0, 0, 0, 0.20));
  width:100%;
}

.feature-card{
  box-shadow: 1px 5px 20px rgba(0, 0, 0, 0.10);
  border-color: #ECF1FF;
}

@media (max-width:768px){
  .slick-prev,
  .slick-next {
    display: none!important;
  }
}

@media (max-width: 576px){
  .card-title.feature-title{
    font-size:2vw;
  }

  .card-text.feature-text{
    font-size:1.6vw;
  }

  .feature-card{
    height:20vw;
  }
}

@media (min-width: 576px){

  .card-title.feature-title{
    font-size:3vw;
  }

  .card-text.feature-text{
    font-size:1.8vw;
  }

  .feature-card{
    height:20vw;
  }

}

@media (min-width: 768px){

  .card-title.feature-title{
    font-size:1.1vw;
  }

  .card-text.feature-text{
    font-size:0.8vw;
  }

  .feature-card{
    height:12vw;
  }
}

@media (min-width: 992px){

  .card-title.feature-title{
    font-size:1.3vw;
  }

  .card-text.feature-text{
    font-size:0.8vw;
  }

  .feature-card{
    height:10vw;
  }
}

@media (min-width: 1200px){
  .card-title.feature-title{
    font-size:1.5vw;
  }

  .card-text.feature-text{
    font-size:0.9vw;
  }

  .feature-card{
    height:10vw;
  }
}
