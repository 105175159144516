.team-section {
  width: 100vw;
  height: 100%;
}

.slider-team.slick-slider.slick-initialized {
  width: 80vw;
}

.slick-prev,
.slick-next {
  height: 55px;
  width: 55px;
  background: white;
  color: #fff;
  box-shadow: 1px 2px 14px rgba(0, 0, 0, 0.75);
  /* to position the arrows left and right at the bottom of slider */
  z-index: 2;
  bottom: 0;
  border-radius: 50%;
}

.slick-next:before {
  content: url("/img/Arrow_right_grey.svg");
  background: white;
  color: #fff;
}

.slick-prev:before {
  content: url("/img/Arrow_left_grey.svg");
  background: white;
  color: #fff;
}

.photo-container {
  position: relative;
}

.team-member-photo {
  position: absolute;
  width: 40%;
  margin-top: -20%;
  z-index: 3;
  transition: opacity 0.2s ease-in-out;
  background: transparent !important;
  border:none;
}

.team-member-photo__color {
  opacity: 0;
  -webkit-filter: drop-shadow(5px 5px 5px #c3d5fa);
  filter: drop-shadow(1px 1px 14px #c3d5fa);
}

.team-member-photo__color:hover {
  opacity: 1;
}

.card-footer:hover {
  cursor: pointer;
}

.card.team-member-card {
  margin-top: 40%;
}

.slick-dots.show-dots {
  position: relative;
}

@media (max-width: 576px) {
  .card-text.team-member-description {
    font-size: 0.8rem;
  }

  .team-member-card {
    height: 17rem;
  }

  .team-section-header {
    font-size: 2.6rem;
  }

  .team-section-text__responsive {
    font-size: 0.8rem;
  }
}

@media (min-width: 576px) {

  .team-section-header {
    font-size: 50px;
  }

  .team-section-text__responsive {
    font-size: 2.7vw;
  }

  .card-text.team-member-description {
    font-size: 0.8rem;
  }

  .team-member-card {
    height: 18rem;
  }
}

@media (min-width: 768px) {

  .card-title.team-member-name {
    font-size: 1rem;
  }

  .card-text.team-member-description {
    font-size: 0.5rem;
  }

  .team-member-card {
    height: calc(14rem + ((100vw - 1200px) / 50));
  }

  .team-section-header {
    font-size: 50px;
  }

  .team-section-text__responsive {
    font-size: 2vw;
  }
}

@media (min-width: 992px) {

  .team-member-card {
    height: 16rem;
  }

  .card-title.team-member-name {
    font-size: 1.2rem;
  }

  .card-text.team-member-description {
    font-size: 0.6rem;
  }

  .team-section-text__responsive {
    font-size: 1.4vw;
  }
}


@media (min-width: 1200px) {
  .team-member-card {
    height: 18rem;
  }

  .card-title.team-member-name {
    font-size: 1.2rem;
  }

  .card-text.team-member-description {
    font-size: 0.8rem;
  }
}






