.landing-section {
  background-color: #123b8b;
  background-image: url("/img/rectangle_landing_page.svg");
  background-repeat: no-repeat;
  width: 100vw;
  height: 100%;
}

.fill {
}

.landing-section-image{
  animation: enter-screen-right-image 1.5s backwards 2s
}

.landing-section-image-container{
  overflow: hidden;
}

@keyframes enter-screen-right-image {
  0% {
    margin-left: -50vw;
  }
  100% {
    margin-left: 0;
  }
}

@keyframes enter-screen-right {
  0% {
    margin-left: -40vw;
    opacity:0;
  }
  50%{
    opacity:0;
  }
  100% {
    margin-left: 0;
    opacity:1;
  }
}

@keyframes enter-screen-top {
  0% {
    margin-top: -10vw;
    opacity:0;
  }
  50%{
    opacity:0;
  }
  100% {
    margin-top: 0;
    opacity:1;
  }
}


.landing-section-header-container {
  text-overflow: clip;
  overflow: hidden;
}

.landing-section-header {
  animation: enter-screen-right 1.5s backwards 0.2s;
  text-align: left;
  color: white;
}

.the-right-way {
  animation: enter-screen-right 1.5s backwards 0.2s;
}

.landing-section-text {
  text-align: left;
  color: white;
  animation: enter-screen-top 1.5s backwards 1.7s;
}

.demo-button{
  animation: enter-screen-top 1.5s backwards 1.8s;
}

.sign-up-button{
  animation: enter-screen-top 1.5s backwards 1.9s;
  color:white!important;
}

.sign-up-button:hover{
  color:#123b8b!important;
  text-underline: none!important;
}

@Media (max-width:576px){
  .landing-section{
    height: 100vw
  }
}


@media (min-width: 768px) {
  .landing-section-header {
    font-size: 4vw;
  }

  .landing-section-text__responsive {
    font-size: 1.2vw;
  }
}

@media (max-width: 768px) {

  .landing-section-image{
    display:none;
  }

  .landing-section-header {
    padding-top: 5vw;
    font-size: 36px;
  }

  .landing-section-text__responsive {
    font-size: 2.7vw;
  }

  .landing-section-content {
    padding-bottom: 5vw
  }
}





