@keyframes enter-screen-top-features {
  0% {
    margin-top: -30px;
  }
  100% {
    margin-top: 0;
  }
}

.fill {
  width: 100%;
}

.features-image {
  z-index: -1;
  position: absolute;
  width: 100%;
  margin-left: -50vw;
}

@media (max-width: 576px) {
  .features-image {
    margin-top: calc(10px - (90vw / 40));
  }
}


@media (min-width: 576px) {
  .features-image {
    margin-top: calc(10px - (90vw / 8));
  }
}

@media (min-width: 764px) {
  .features-image {
    margin-top: calc(10px - (90vw) / 2.5);
  }
}

@media (min-width: 992px) {
  .features-image {
    margin-top: calc(10px - (90vw / 2));
  }
}

@media (min-width: 1200px) {
  .features-image {
    margin-top: calc(10px - (90vw / 1.8));
  }
}


.gale {
  box-shadow: 1px 5px 20px rgba(0, 0, 0, 0.10);
  background-color: #0D122A;
  color: white;
  border-radius: 0px 0px 20px 20px;
  z-index: 1;
}

.animate-gale {
  animation: enter-screen-top-features 1.5s backwards 1.8s;
}

.root {
  overflow: hidden;
}

@media (max-width:992px) {
  .anchor{
    scroll-margin-top: 283px;
  }
}

@media (min-width:992px) {
  .anchor{
    scroll-margin-top: 70px;
  }
}
