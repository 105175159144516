

@media (min-width: 992px) {

  .sign-up-image{
    padding: 8rem 0rem 6rem 0rem;
  }

  .success-container {
    padding-left: 6rem !important;
  }

  .welcome{
    font-size: 4rem;
  }

  .message{
    font-size: 1.5rem;
  }
}


@media (max-width: 992px){
  .sign-up-image{
    padding: 4rem 0rem 0rem 0rem;
    margin-bottom: -2rem;
    z-index: -1;
  }

  .welcome{
    font-size: 2rem;
    margin-left: auto;
    margin-right: auto;
    padding-top: 2rem;
  }

  .message{
    font-size: 1rem;
    text-align: center!important;
  }

  .okay-button{
    margin-left: auto!important;
    margin-right: auto!important;
  }
}

.message{
  text-align: left;
}


