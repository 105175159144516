select[required]:invalid {
  color: #737373
}

select {
  padding-left: 1.5rem !important;
  height: 3rem !important;
}

.custom.custom-file {
  height: 3rem !important;
}

#custom-file-translate-html {
  height: 3rem !important;
}

.custom-file-label {
  height: 3rem !important;
  padding-top: 12px;
  padding-right: 82px;
}

.custom-file-label::after {
  height: 2.85rem !important;
  padding-top: 12px;
}

.registration-form-container {
  background-color: #123B8B;
}

.register {
  margin-left: 0px;
  margin-bottom: 30px
}

.main-header {
  font-size: 2rem !important;
}

@media (min-width: 992px) {
  .heading {
    align-self: flex-start !important;
  }

  .registration-form-container {
    padding-left: 6rem !important;
  }

  .main-header {
    font-size: 3vw !important;
    padding-top: 4rem !important;
    padding-bottom: 1.5rem !important;
  }

  .register {
    align-self: start!important;
  }
}

