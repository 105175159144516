@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@200&display=swap);
@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600&display=swap);
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Poppins', sans-serif!important;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

@-webkit-keyframes enter-screen-top-features {
  0% {
    margin-top: -30px;
  }
  100% {
    margin-top: 0;
  }
}

@keyframes enter-screen-top-features {
  0% {
    margin-top: -30px;
  }
  100% {
    margin-top: 0;
  }
}

.fill {
  width: 100%;
}

.features-image {
  z-index: -1;
  position: absolute;
  width: 100%;
  margin-left: -50vw;
}

@media (max-width: 576px) {
  .features-image {
    margin-top: calc(10px - (90vw / 40));
  }
}


@media (min-width: 576px) {
  .features-image {
    margin-top: calc(10px - (90vw / 8));
  }
}

@media (min-width: 764px) {
  .features-image {
    margin-top: calc(10px - (90vw) / 2.5);
  }
}

@media (min-width: 992px) {
  .features-image {
    margin-top: calc(10px - (90vw / 2));
  }
}

@media (min-width: 1200px) {
  .features-image {
    margin-top: calc(10px - (90vw / 1.8));
  }
}


.gale {
  box-shadow: 1px 5px 20px rgba(0, 0, 0, 0.10);
  background-color: #0D122A;
  color: white;
  border-radius: 0px 0px 20px 20px;
  z-index: 1;
}

.animate-gale {
  -webkit-animation: enter-screen-top-features 1.5s backwards 1.8s;
          animation: enter-screen-top-features 1.5s backwards 1.8s;
}

.root {
  overflow: hidden;
}

@media (max-width:992px) {
  .anchor{
    scroll-margin-top: 283px;
  }
}

@media (min-width:992px) {
  .anchor{
    scroll-margin-top: 70px;
  }
}

@media (max-width: 768px){
  .navbar-brand{
    margin-left: 1rem;
  }

  .nav-link-item{
    padding-left: 0px;
    margin-right: 0px;
  }
}

@media (min-width:992px){
  .nav-link-item{
    padding-left: 3rem;
    margin-right: 3rem;
  }
}

.sign-up-header{
  color:white!important;
}

.sign-up-header:hover{
  color:#123b8b!important;
  text-underline: none!important;
}

.landing-section {
  background-color: #123b8b;
  background-image: url("/img/rectangle_landing_page.svg");
  background-repeat: no-repeat;
  width: 100vw;
  height: 100%;
}

.fill {
}

.landing-section-image{
  -webkit-animation: enter-screen-right-image 1.5s backwards 2s;
          animation: enter-screen-right-image 1.5s backwards 2s
}

.landing-section-image-container{
  overflow: hidden;
}

@-webkit-keyframes enter-screen-right-image {
  0% {
    margin-left: -50vw;
  }
  100% {
    margin-left: 0;
  }
}

@keyframes enter-screen-right-image {
  0% {
    margin-left: -50vw;
  }
  100% {
    margin-left: 0;
  }
}

@-webkit-keyframes enter-screen-right {
  0% {
    margin-left: -40vw;
    opacity:0;
  }
  50%{
    opacity:0;
  }
  100% {
    margin-left: 0;
    opacity:1;
  }
}

@keyframes enter-screen-right {
  0% {
    margin-left: -40vw;
    opacity:0;
  }
  50%{
    opacity:0;
  }
  100% {
    margin-left: 0;
    opacity:1;
  }
}

@-webkit-keyframes enter-screen-top {
  0% {
    margin-top: -10vw;
    opacity:0;
  }
  50%{
    opacity:0;
  }
  100% {
    margin-top: 0;
    opacity:1;
  }
}

@keyframes enter-screen-top {
  0% {
    margin-top: -10vw;
    opacity:0;
  }
  50%{
    opacity:0;
  }
  100% {
    margin-top: 0;
    opacity:1;
  }
}


.landing-section-header-container {
  text-overflow: clip;
  overflow: hidden;
}

.landing-section-header {
  -webkit-animation: enter-screen-right 1.5s backwards 0.2s;
          animation: enter-screen-right 1.5s backwards 0.2s;
  text-align: left;
  color: white;
}

.the-right-way {
  -webkit-animation: enter-screen-right 1.5s backwards 0.2s;
          animation: enter-screen-right 1.5s backwards 0.2s;
}

.landing-section-text {
  text-align: left;
  color: white;
  -webkit-animation: enter-screen-top 1.5s backwards 1.7s;
          animation: enter-screen-top 1.5s backwards 1.7s;
}

.demo-button{
  -webkit-animation: enter-screen-top 1.5s backwards 1.8s;
          animation: enter-screen-top 1.5s backwards 1.8s;
}

.sign-up-button{
  -webkit-animation: enter-screen-top 1.5s backwards 1.9s;
          animation: enter-screen-top 1.5s backwards 1.9s;
  color:white!important;
}

.sign-up-button:hover{
  color:#123b8b!important;
  text-underline: none!important;
}

@Media (max-width:576px){
  .landing-section{
    height: 100vw
  }
}


@media (min-width: 768px) {
  .landing-section-header {
    font-size: 4vw;
  }

  .landing-section-text__responsive {
    font-size: 1.2vw;
  }
}

@media (max-width: 768px) {

  .landing-section-image{
    display:none;
  }

  .landing-section-header {
    padding-top: 5vw;
    font-size: 36px;
  }

  .landing-section-text__responsive {
    font-size: 2.7vw;
  }

  .landing-section-content {
    padding-bottom: 5vw
  }
}






.why-gale-section.container-fluid {
  padding-top: 30px;
  width: 100vw;
  height: 100%;
}

.fill {
  width: 100%;
}

.icon{
  width: 20px;
}

.why-gale-section-header {
  text-align: left;
}

.why-gale-section-text {
  text-align: left;
}

.float-full-form{
  margin-top:-30px;
  font-size: 1.2vw;
}


@media (min-width: 768px) {

  .why-gale-section-header {
    font-size: 2.2vw;
  }

  .why-gale-section-text__responsive{
    font-size: 1.2vw;
    padding-left: calc(16px - (1640px - 100vw)/152);
  }

  .icon{
    width: calc(27px - (1640px - 100vw)/152);
  }
}

@media (max-width: 768px) {

  .float-full-form{
    margin-top:-30px;
    font-size: 16px;
  }

  .why-gale-section-header {
    font-size: 36px;
  }

  .why-gale-section-text__responsive{
    font-size: 16px;
    padding-left: 1rem;
  }

  .icon{
    width: 27px;
  }

  .why-gale-section-content{
    padding-bottom: 5vw
  }
}


.team-section {
  width: 100vw;
  height: 100%;
}

.slider-team.slick-slider.slick-initialized {
  width: 80vw;
}

.slick-prev,
.slick-next {
  height: 55px;
  width: 55px;
  background: white;
  color: #fff;
  box-shadow: 1px 2px 14px rgba(0, 0, 0, 0.75);
  /* to position the arrows left and right at the bottom of slider */
  z-index: 2;
  bottom: 0;
  border-radius: 50%;
}

.slick-next:before {
  content: url("/img/Arrow_right_grey.svg");
  background: white;
  color: #fff;
}

.slick-prev:before {
  content: url("/img/Arrow_left_grey.svg");
  background: white;
  color: #fff;
}

.photo-container {
  position: relative;
}

.team-member-photo {
  position: absolute;
  width: 40%;
  margin-top: -20%;
  z-index: 3;
  transition: opacity 0.2s ease-in-out;
  background: transparent !important;
  border:none;
}

.team-member-photo__color {
  opacity: 0;
  -webkit-filter: drop-shadow(5px 5px 5px #c3d5fa);
  filter: drop-shadow(1px 1px 14px #c3d5fa);
}

.team-member-photo__color:hover {
  opacity: 1;
}

.card-footer:hover {
  cursor: pointer;
}

.card.team-member-card {
  margin-top: 40%;
}

.slick-dots.show-dots {
  position: relative;
}

@media (max-width: 576px) {
  .card-text.team-member-description {
    font-size: 0.8rem;
  }

  .team-member-card {
    height: 17rem;
  }

  .team-section-header {
    font-size: 2.6rem;
  }

  .team-section-text__responsive {
    font-size: 0.8rem;
  }
}

@media (min-width: 576px) {

  .team-section-header {
    font-size: 50px;
  }

  .team-section-text__responsive {
    font-size: 2.7vw;
  }

  .card-text.team-member-description {
    font-size: 0.8rem;
  }

  .team-member-card {
    height: 18rem;
  }
}

@media (min-width: 768px) {

  .card-title.team-member-name {
    font-size: 1rem;
  }

  .card-text.team-member-description {
    font-size: 0.5rem;
  }

  .team-member-card {
    height: calc(14rem + ((100vw - 1200px) / 50));
  }

  .team-section-header {
    font-size: 50px;
  }

  .team-section-text__responsive {
    font-size: 2vw;
  }
}

@media (min-width: 992px) {

  .team-member-card {
    height: 16rem;
  }

  .card-title.team-member-name {
    font-size: 1.2rem;
  }

  .card-text.team-member-description {
    font-size: 0.6rem;
  }

  .team-section-text__responsive {
    font-size: 1.4vw;
  }
}


@media (min-width: 1200px) {
  .team-member-card {
    height: 18rem;
  }

  .card-title.team-member-name {
    font-size: 1.2rem;
  }

  .card-text.team-member-description {
    font-size: 0.8rem;
  }
}







.contact-us-section.container-fluid {
  padding-top: 30px;
  width: 100vw;
  height: 100%;
  color: white;
  justify-content: center;
  align-items: center;
  background: url("/img/left_shape.svg") left bottom no-repeat,
  url("/img/right_shape.svg") right top no-repeat,
  #123b8b;
}

form {
  width: 100%;
}

.text-area.form-control {
  height: 7.2rem !important;
}

@media (max-width: 768px) {
  .mt-5.form {
    margin-top: 3rem !important;
  }

  .mt-5 {
    margin-top: 0rem !important;
  }
}

.Toastify__toast--info {
  background:url("/img/right_shape.svg") right top no-repeat
      #123b8b !important;
  border-radius: 3px !important;
}

.footer-section.container-fluid{
  background-color: #0D122A;
  color: white;
}

@media (min-width:576px){
  .copyright{
    margin-top:-1rem;
    padding-bottom: 1.2rem;
  }

  .gale-logo{
    padding-top: 0rem!important;
  }
}

@media (max-width:768px){
  .social-media-icons{
    margin-left: auto !important
  }

  .address{
    margin-bottom: 1rem;
  }
}

@media (min-width:768px){
  .address{
    margin-left: auto !important
  }
}

.gale-logo-big{
  width:150px;
}

.features-section {
  width: 100vw;
  height: 100%;
}

.slider-features.slick-slider.slick-initialized{
  width:80vw;
}

.slick-prev,
.slick-next {
  height: 40px;
  width: 40px;
  background: white;
  color: #fff;
  box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.25);
  /* to position the arrows left and right at the bottom of slider */
  position: absolute;
  z-index: 1;
  bottom: 0;
  border-radius: 50%;
}

.slick-arrow.slick-prev:hover,
.slick-arrow.slick-next:hover{
  background: white;
}

.slick-prev{
  margin-left:-50px;
}

.slick-next{
  margin-right:-50px;
}

.slick-dots.show-dots{
  margin-top:-30px;
  margin-bottom:30px;
}

.slick-current .feature-card{
  border-top-color: #123b8b;
  border-top-width: 0.3vw;
  transform: scale(1.2);
  transition: border-top-color 2s ease-out, transform 0.5s;
}

.feature-image{
  -webkit-filter: drop-shadow(1px 1px 20px rgba(0, 0, 0, 0.20));
  filter: drop-shadow(1px 1px 20px rgba(0, 0, 0, 0.20));
  width:100%;
}

.feature-card{
  box-shadow: 1px 5px 20px rgba(0, 0, 0, 0.10);
  border-color: #ECF1FF;
}

@media (max-width:768px){
  .slick-prev,
  .slick-next {
    display: none!important;
  }
}

@media (max-width: 576px){
  .card-title.feature-title{
    font-size:2vw;
  }

  .card-text.feature-text{
    font-size:1.6vw;
  }

  .feature-card{
    height:20vw;
  }
}

@media (min-width: 576px){

  .card-title.feature-title{
    font-size:3vw;
  }

  .card-text.feature-text{
    font-size:1.8vw;
  }

  .feature-card{
    height:20vw;
  }

}

@media (min-width: 768px){

  .card-title.feature-title{
    font-size:1.1vw;
  }

  .card-text.feature-text{
    font-size:0.8vw;
  }

  .feature-card{
    height:12vw;
  }
}

@media (min-width: 992px){

  .card-title.feature-title{
    font-size:1.3vw;
  }

  .card-text.feature-text{
    font-size:0.8vw;
  }

  .feature-card{
    height:10vw;
  }
}

@media (min-width: 1200px){
  .card-title.feature-title{
    font-size:1.5vw;
  }

  .card-text.feature-text{
    font-size:0.9vw;
  }

  .feature-card{
    height:10vw;
  }
}

select[required]:invalid {
  color: #737373
}

select {
  padding-left: 1.5rem !important;
  height: 3rem !important;
}

.custom.custom-file {
  height: 3rem !important;
}

#custom-file-translate-html {
  height: 3rem !important;
}

.custom-file-label {
  height: 3rem !important;
  padding-top: 12px;
  padding-right: 82px;
}

.custom-file-label::after {
  height: 2.85rem !important;
  padding-top: 12px;
}

.registration-form-container {
  background-color: #123B8B;
}

.register {
  margin-left: 0px;
  margin-bottom: 30px
}

.main-header {
  font-size: 2rem !important;
}

@media (min-width: 992px) {
  .heading {
    align-self: flex-start !important;
  }

  .registration-form-container {
    padding-left: 6rem !important;
  }

  .main-header {
    font-size: 3vw !important;
    padding-top: 4rem !important;
    padding-bottom: 1.5rem !important;
  }

  .register {
    align-self: start!important;
  }
}




@media (min-width: 992px) {

  .sign-up-image{
    padding: 8rem 0rem 6rem 0rem;
  }

  .success-container {
    padding-left: 6rem !important;
  }

  .welcome{
    font-size: 4rem;
  }

  .message{
    font-size: 1.5rem;
  }
}


@media (max-width: 992px){
  .sign-up-image{
    padding: 4rem 0rem 0rem 0rem;
    margin-bottom: -2rem;
    z-index: -1;
  }

  .welcome{
    font-size: 2rem;
    margin-left: auto;
    margin-right: auto;
    padding-top: 2rem;
  }

  .message{
    font-size: 1rem;
    text-align: center!important;
  }

  .okay-button{
    margin-left: auto!important;
    margin-right: auto!important;
  }
}

.message{
  text-align: left;
}



