@media (max-width: 768px){
  .navbar-brand{
    margin-left: 1rem;
  }

  .nav-link-item{
    padding-left: 0px;
    margin-right: 0px;
  }
}

@media (min-width:992px){
  .nav-link-item{
    padding-left: 3rem;
    margin-right: 3rem;
  }
}

.sign-up-header{
  color:white!important;
}

.sign-up-header:hover{
  color:#123b8b!important;
  text-underline: none!important;
}
